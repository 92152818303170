import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LoginData, LoginResponse } from './login/models/login';
import { ProfileResponse } from './shared/models/profile';
import { UserResponse, UsersResponse } from './shared/models/users';
import { ReportsResponse } from './layout/children/reports/models/reports';
import { DisputeResponse, TransactionResponse } from './shared/models/dispute.models';
import { ReportsCategoryResponse, ReviewDisputesResponse } from './shared/models/reports.models';
import { FeesI, FeesResponse, GiftsI, GiftsResponse } from './shared/models/fees.models';
import { CommissionStatisticResponse, InterestStatsResponse, RegistrationTypesResponse, TopUserByIncomeResponse, TotalCountResponse, UserStatisticFollowersResponse, UserStatisticTransactionResponse } from './shared/models/statistic.models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly httpService: HttpClient) {}

  login(loginData: LoginData) {
    return this.httpService.post<LoginResponse>(`${environment.URL}admins/login`, loginData);
  }

  getAdminProfile(id: string) {
    return this.httpService.get<ProfileResponse>(`${environment.URL}admins/${id}`);
  }

  getAllGifts() {
    return this.httpService.get<GiftsResponse>(`${environment.URL}gifts`);
  }

  getUsers(state: number | null, verified: boolean | null, page?: number) {
    return this.httpService.get<UsersResponse>(`${environment.URL}admins/users?limit=12&offset=${page}${state !== null ? `&state=${state}` : ''}${verified !== null ? `&is_verified=${verified}` : ''}&sort_by=id_desc`);
  }

  getReports(page: number, complaintTypeSelect: number | null, complaintStatusSelect: number | null, sortName: string) {
    return this.httpService.get<ReportsResponse>(`${environment.URL}admins/users/reports?limit=12&offset=${page}${complaintTypeSelect !== null ? `&category_id=${complaintTypeSelect}`: ''}${complaintStatusSelect !== null ? `&status=${complaintStatusSelect}` : ''}${sortName ? `&sort_by=${sortName.split('_')[0]}&sort_order=${sortName.split('_')[1].toUpperCase()}` : ''}`);
  }

  blockUser(id: number, block_until: string) {
    return this.httpService.patch(`${environment.URL}admins/users/${id}/block`, { block_until: block_until });
  }

  unblockUser(id: number) {
    return this.httpService.patch(`${environment.URL}admins/users/${id}/unblock`, {});
  }

  deleteUser(id: number) {
    return this.httpService.delete(`${environment.URL}admins/users/${id}`);
  }

  searchUsers(search: string) {
    return this.httpService.get<UsersResponse>(`${environment.URL}admins/users?limit=12&offset=0&q=${search}`);
  }

  getUserById(id: string) {
    return this.httpService.get<UserResponse>(`${environment.URL}admins/users/${id}`);
  }

  getDisputes(page: number) {
    return this.httpService.get<DisputeResponse>(`${environment.URL}admins/users/projects/disputes?limit=12&offset=${page}`);
  }

  searchTransactionDisputes(search: string) {
    return this.httpService.get<DisputeResponse>(`${environment.URL}admins/users/projects/disputes?limit=12&offset=0&q=${search}`);
  }

  resolveDisputes(id: number, status: number) {
    return this.httpService.patch(`${environment.URL}admins/users/projects/disputes/${id}`, {verdict: status});
  }

  getReportsCategories() {
    return this.httpService.get<ReportsCategoryResponse>(`${environment.URL}reports/categories`);
  }

  getActiveTransaction(page: number) {
    return this.httpService.get<TransactionResponse>(`${environment.URL}admins/users/escrow/transactions?sort_by=created_at&sort_order=DESC&limit=12&offset=${page}`);
  }

  getFees() {
    return this.httpService.get<FeesResponse>(`${environment.URL}fees`);
  }

  deleteGifts(giftsId: number) {
    return this.httpService.delete(`${environment.URL}gifts/${giftsId}`);
  }

  updateGift(giftsId: number, gift: GiftsI) {
    return this.httpService.patch(`${environment.URL}gifts/${giftsId}`, gift);
  }

  searchReports(search: string) {
    return this.httpService.get<ReportsResponse>(`${environment.URL}admins/users/reports?limit=12&offset=0&q=${search}`);
  }

  updateFee(fee: FeesI) {
    return this.httpService.patch(`${environment.URL}fees`, fee);
  }

  getReviewDisputes(page: number, rating: null | number, status: null | number, sortName: string) {
    return this.httpService.get<ReviewDisputesResponse>(`${environment.URL}admins/users/reviews/disputes?limit=12&offset=${page}${status !== null ? `&status=${status}` : ''}${rating !== null ? `&rating=${rating}` : ''}${sortName ? `&sort_by=${sortName.split('_')[0]}&sort_order=${sortName.split('_')[1].toUpperCase()}` : ''}`);
  }

  deleteReport(id: number) {
    return this.httpService.delete(`${environment.URL}admins/users/reports/${id}`);
  }

  rejectReport(id: number, status: number) {
    return this.httpService.patch(`${environment.URL}admins/users/reports/${id}`, {status: status});
  }

  searchDisputes(search: string) {
    return this.httpService.get<ReviewDisputesResponse>(`${environment.URL}admins/users/projects/disputes?limit=12&offset=0&q=${search}`);
  }

  deleteDispute(id: number) {
    return this.httpService.delete(`${environment.URL}admins/users/reviews/disputes/${id}`);
  }

  rejectDispute(id: number, status: number) {
    return this.httpService.patch(`${environment.URL}admins/users/reviews/disputes/${id}`, {status: status});
  }

  deleteReview(id: number){
    return this.httpService.delete(`${environment.URL}admins/users/reviews/${id}`);
  }

  getUserStatisticFollowers(id: number, startTime: number, endTime: number) {
    return this.httpService.get<UserStatisticFollowersResponse>(`${environment.URL}admins/users/${id}/statistics/followers?start_time=${Math.floor(startTime / 1000)}&end_time=${Math.floor(endTime / 1000)}`);
  }

  getUserStatisticTransaction(id: number, startTime: number, endTime: number) {
    return this.httpService.get<UserStatisticTransactionResponse>(`${environment.URL}admins/users/${id}/statistics/transactions?start_time=${startTime}&end_time=${endTime}`);
  }

  getTotalCount(startTime: number | null, endTime: number | null) {
    return this.httpService.get<TotalCountResponse>(`${environment.URL}admins/users/statistics/daily-count${startTime !== null ? `?start_time=${startTime}&end_time=${endTime}` : ''}`);
  }

  getRegistrationMethod() {
    return this.httpService.get<RegistrationTypesResponse>(`${environment.URL}admins/users/statistics/registration-methods`);
  }

  getInterest() {
    return this.httpService.get<InterestStatsResponse>(`${environment.URL}admins/users/statistics/interests/main`);
  }

  getTopUsersByIncome(startTime: Date | null, endTime: Date | null) {
    let startTimeInSeconds;
    let endTimeInSeconds;
    if (startTime && endTime) {
      startTimeInSeconds = Math.floor(startTime.getTime());
      endTimeInSeconds = Math.floor(endTime.getTime());
    }

    return this.httpService.get<TopUserByIncomeResponse>(`${environment.URL}admins/users/statistics/top-by-income${startTime !== null ? `?start_time=${startTimeInSeconds}&end_time=${endTimeInSeconds}` : ''}`)
  }

  getCommissionStatistic(startTime: Date | null, endTime: Date | null) {
    let startTimeInSeconds;
    let endTimeInSeconds;
    if (startTime && endTime) {
      startTimeInSeconds = Math.floor(startTime.getTime());
      endTimeInSeconds = Math.floor(endTime.getTime());
    }

    return this.httpService.get<CommissionStatisticResponse>(`${environment.URL}admins/users/statistics/commissions${startTime !== null ? `?start_time=${startTimeInSeconds}&end_time=${endTimeInSeconds}` : ''}`)
  }

  deleteAppointmentDispute(id: number) {
    return this.httpService.delete(`${environment.URL}admins/users/projects/disputes/${id}`);
  }
}
